<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">
						Рецензия на
						<span v-html="channel.title" />
					</span>
				</v-card-title>
				<v-card-text>
					<v-alert>
						Рецензия не должна быть опубликована на любой другом сайте. Минимальное количество символов - 300. На одно видео может быть написана только одна рецензия.
					</v-alert>
					<v-form ref="form" v-model="valid" loading="loading">
						<v-text-field
							label="Заголовок"
							autofocus
							v-model="title"
							outlined
							single-line
							hint="Заголовок должен быть кратким и ёмким"
							:rules="[rule_required, rule_length_min(10)]"
							@focus="onFocus"
						></v-text-field>
						<v-textarea outlined auto-grow v-model="text" counter :rules="[rule_required, rule_length_min(300)]" @focus="onFocus" />
						<v-rating v-model="rating"></v-rating>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" text @click="dialog = false">Закрыть</v-btn>
					<v-btn color="green" text @click="save" :disabled="!valid">Сохранить</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="showMessage" :timeout="5000" top>
			{{ message }}
			<v-btn text @click="showMessage = false">Закрыть</v-btn>
		</v-snackbar>
	</div>
</template>
<script>
import { Validator } from "../../mixins/Validator";

export default {
	props: ["channel"],
	mixins: [Validator],
	data() {
		return {
			dialog: false,
			title: "",
			text: "",
			rating: 0,
			loading: false,
			valid: false,
			message: false,
			showMessage: false,
		};
	},
	methods: {
		openDialog() {
			this.dialog = true;
		},
		onFocus() {
			this.$eventBus.$emit('player-focus', false);
		},
		save() {
			let t = this;
			t.loading = true;
			if (!t.rating) {
				t.message = "Рецензия должна содержать оценку по пятибальной шкале.";
				t.showMessage = true;
				return;
			}
			t.showMessage = false;

			window.ajax(
				"/api/channel/review/save",
				{
					body: {
						title: t.title,
						text: t.text,
						rate: t.rating,
						channel_id: t.channel.id
					}
				},
				(data, error) => {
					this.loading = false;
					if (!data.error && !error) {
						t.dialog = false;
						t.message = "Рецензия отправлена на модерацию. Спасибо!";
						t.showMessage = true;
						this.$eventBus.$emit('player-focus', true);
					} else {
						t.message = data.error || error;
						t.showMessage = true;
					}
				}
			);
		}
	}
};
</script>
